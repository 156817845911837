import React, { useEffect, useRef, useState } from "react";

export const VideoPlayer = ({ videoId }) => {
  const [shouldLoad, setShouldLoad] = useState(false);

  // use intersection observer to only load the video when it's in view
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) =>
      entries.forEach((entry) => entry.isIntersecting && setShouldLoad(true))
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  const videoUrl = new URL(`https://www.youtube.com/embed/${videoId}`);
  videoUrl.searchParams.set("autoplay", "1");
  videoUrl.searchParams.set("color", "white");
  videoUrl.searchParams.set("loop", "1");
  videoUrl.searchParams.set("mute", "1");
  videoUrl.searchParams.set("origin", "https://wasatchcover.com");
  videoUrl.searchParams.set("playlist", videoId);
  videoUrl.searchParams.set("playsinline", "1");
  videoUrl.searchParams.set("rel", "0");

  return (
    <iframe
      ref={ref}
      width="560"
      height="315"
      src={shouldLoad ? videoUrl.toString() : ""}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      className="aspect-video h-full w-full rounded-2xl"
    ></iframe>
  );
};
